import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeDeCH from '@angular/common/locales/de-CH';
import localeEnGB from '@angular/common/locales/en-GB';
import localeFrCH from '@angular/common/locales/fr-CH';

import localeDe from '@angular/common/locales/de';
import { APP_INITIALIZER, CSP_NONCE, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MatMomentDateModule,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { AppState } from '@app/store/app.state';
import { AdtracRouterStateSerializer } from '@app/store/router/router-state.serializer';
import { AuthModule } from '@auth/auth.module';
import { AuthenticationInterceptor } from '@auth/interceptors/authentication.interceptor';
import { Language } from '@campaign/models/asset.model';
import { CampaignState } from '@campaign/store/campaign.state';
import { CoreModule } from '@core/core.module';
import { BlockedReqeustInterceptor } from '@core/interceptors/server-error/blocked-request.interceptor';
import { OfflineInterceptor } from '@core/interceptors/server-error/offline.interceptor';
import { ServerErrorInterceptor } from '@core/interceptors/server-error/server-error.interceptor';
import { GlobalErrorHandler } from '@core/services/error-handler/error-handler.service';
import { JiraWidgetService } from '@core/services/jira-widget/jira-widget.service';
import {
  LANGUAGE_ENGLISH,
  LANGUAGE_FRENCH,
  LANGUAGE_GERMAN,
  LANGUAGE_GERMAN_GERMAN,
  LANGUAGE_GERMAN_SWITZERLAND,
} from '@core/services/language/language.model';
import { LocalizedDatePipe } from '@core/services/language/localized-date.pipe';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule, RouterStateSerializer } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NGXS_PLUGINS, NgxsModule } from '@ngxs/store';
import { SalesChannelState } from '@sales-channel/store/sales-channel.state';
import { TraceService } from '@sentry/angular';
import { AppUpdateComponent } from '@shared/components/app-update/app-update.component';
import { OfflineBannerComponent } from '@shared/components/offline-banner/offline-banner.component';
import { ServiceInjectorModule } from '@shared/service-injector.module';
import { SharedModule } from '@shared/shared.module';
import { UserDetailDialogComponent } from '@user-management/component/user-detail-dialog/user-detail-dialog.component';
import 'moment/locale/de';
import 'moment/locale/fr';
import { environment } from '../environments/environment';
import { StoreInterceptor } from './features/auth/interceptors/store.interceptor';
import { SiteManagementState } from './features/site-management/store/site-management.state';
// register supported languages
registerLocaleData(localeEnGB, LANGUAGE_ENGLISH);
registerLocaleData(environment?.locale == Language.german ? localeDe : localeDeCH, LANGUAGE_GERMAN);
registerLocaleData(localeFrCH, LANGUAGE_FRENCH);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export class CustomDateAdapter extends MomentDateAdapter {
  getFirstDayOfWeek(): number {
    return 1;
  }
}

export const APP_IMPORTS = [
  BrowserModule,
  AppRoutingModule,
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient],
    },
  }),
  BrowserAnimationsModule,
  NgxsModule.forRoot([AppState, CampaignState, SalesChannelState, SiteManagementState], {
    developmentMode: !environment.production,
    selectorOptions: {
      suppressErrors: false,
      injectContainerState: false,
    },
  }),
  NgxsStoragePluginModule.forRoot({ keys: ['app', 'auth', 'sales', 'tenant'] }),
  NgxsReduxDevtoolsPluginModule.forRoot(),
  NgxsRouterPluginModule.forRoot(),
  SharedModule,
  ServiceInjectorModule,
  AuthModule,
  CoreModule,
  MatMomentDateModule,
  ServiceWorkerModule.register('ngsw-worker.js', {
    enabled: environment.production,
    registrationStrategy: 'registerImmediately',
  }),
];

@NgModule({
  declarations: [AppComponent],
  imports: [...APP_IMPORTS, OfflineBannerComponent, AppUpdateComponent, UserDetailDialogComponent],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    { provide: HTTP_INTERCEPTORS, useClass: BlockedReqeustInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: OfflineInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_MOMENT_DATE_FORMATS,
    },
    { provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: LOCALE_ID,
      useValue: environment?.locale == Language.german ? LANGUAGE_GERMAN_GERMAN : LANGUAGE_GERMAN_SWITZERLAND,
    },
    { provide: RouterStateSerializer, useClass: AdtracRouterStateSerializer },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
    LocalizedDatePipe,
    {
      provide: NGXS_PLUGINS,
      useClass: StoreInterceptor,
      deps: [],
      multi: true,
    },
    {
      provide: CSP_NONCE,
      useValue: globalThis.myRandomNonceValue,
    },
    // provideExperimentalZonelessChangeDetection()
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(_jiraWidgetService: JiraWidgetService) { }
}
