import { Injectable } from "@angular/core";
import { newMilestone } from "@app/shared/services/utils-helper.service";
import { NgxsNextPluginFn, NgxsPlugin } from "@ngxs/store";

@Injectable({
    providedIn: 'root',
})
export class StoreInterceptor implements NgxsPlugin {

    public handle(state, action, next: NgxsNextPluginFn): NgxsNextPluginFn {
        let milestoneDetail;
        if (!!action.refreshStore) {
            const { stateName, listAttribute, loadingAttribute, timestampAttribute } = action.refreshStore;
            milestoneDetail = newMilestone(state, action);
            if (!!milestoneDetail?.timestamp) {
                const timestamp = !timestampAttribute ? `${listAttribute}Timestamp` : timestampAttribute;

                const updateState = { ...state, [stateName]: { ...state[stateName], [listAttribute]: null, [timestamp]: milestoneDetail} };
                const updateStateWithLoadingAttribute = { ...state, [stateName]: { ...state[stateName], [listAttribute]: null, [timestamp]: milestoneDetail, [loadingAttribute]: false} };
                state = !!loadingAttribute ? updateStateWithLoadingAttribute : updateState;
            }
        }
        return (!!milestoneDetail?.timestamp || !action.refreshStore) ? next(state, action) : next(state, { action, skip: true });
    }
}